import {MDCDialog} from '@material/dialog';

export class HelpPopup{

    constructor(appManager){
        this.appManager = appManager

        this.initialise()
    }

    initialise(){
        this.helpButton = document.getElementById("help-btn")
        this.helpPopup = new MDCDialog(document.getElementById('helpPopup'))
        this.helpButton.onclick = () => this.openHelpPopup()

    }

    openHelpPopup(){
        this.helpPopup.open()
    }
    closeVideoPopup(){
        
    }
}