export class BackButton{

    constructor(appManager){
        this.appManager = appManager

        this.initialise()
    }

    initialise(){
        this.backButton = document.getElementById("buttonBack")
        this.backButton.onclick = () => this.goBack()

    }

    goBack(){
        if(this.appManager.sceneHistory.length < 2) return
        this.appManager.sceneManager.goBackScene()
    }
}