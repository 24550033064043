import { Model } from "../Objects/model"

export class ModelManager{  

    constructor(appManager){
        this.appManager = appManager
        this.root = new BABYLON.TransformNode("modelRoot");
        this.currentModel = null
        //this.appManager.cameraManager.cameraTarget.parent = this.root

        this.loadModel(this.appManager.data.model, false)
    }

    loadModel(url){
        if(this.currentModel != null) this.removeCurrentModel()

        this.currentModel = new Model(this, url)
    }

    removeCurrentModel(){
        this.currentModel.disposeModel()
        this.currentModel = null
    }
}