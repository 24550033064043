import {MDCSlider} from '@material/slider';
import {MDCRipple} from '@material/ripple';

export class AnimationController{

    constructor(appManager){
        this.appManager = appManager
        this.animationGroups = []
        this.isPlaying = false
        this.currentAnimationGroup
        this.animStart = null
        this.animEnd = null

        this.initialise()
    }

    initialise(){
        this.animationSlider = new MDCSlider(document.getElementById('animationSlider'))
        this.animationPlayButton = new MDCRipple(document.getElementById('playButton'))
        this.animationPlayButtonElement = document.getElementById('playButton')
        this.animationPlayButtonIcon = document.getElementById('playButtonIcon')
        this.animationPlayButtonElement.onclick = () =>  this.toggleAnimation()

        this.animationSlider.listen('MDCSlider:input',() => this.updateAnimationToSlider())
    }

    addAnimationGroup(animationGroup){
        console.log(animationGroup.name)
        animationGroup.stop()
        this.animationGroups.push(animationGroup)
        this.appManager.scene.animationTimeScale = 4
    }

    toggleAnimation(){
        if(this.isPlaying){
            this.pauseAnimation()
        }else{
            this.playAnimation()
        }
        
    }

    pauseAnimation(){
        this.animationPlayButtonIcon.innerHTML = "play_arrow"
        this.currentAnimationGroup.pause()
        this.isPlaying = false
        if (window.interval) {
            clearInterval(window.interval);
        }
    }
    playAnimation(){
        if(this.animationSlider.getValue() == 100)
            this.currentAnimationGroup.goToFrame(this.animStart)
        this.animationPlayButtonIcon.innerHTML = "pause"
        this.isPlaying = true
        this.currentAnimationGroup.play()
        this.updateSliderToAnimation()
    }

    resetAnimation(animationGroup, start, end){
        this.currentAnimationGroup = animationGroup
        if(end){
            this.animStart = start
            this.animEnd = end
        }
        else{
            this.animStart = 0
            this.animEnd = this.currentAnimationGroup._to
        }
        if(animationGroup){
            document.getElementById('bottomBar').classList.remove("hide")
            this.appManager.modelTransparent.highlightParts("null")
            this.appManager.modelHighlight.highlightParts("null")
            this.appManager.productManager.hotspotManager.hideHotspotWidgets()
            //this.animationSlider.setValue(0)
            //this.currentAnimationGroup.goToFrame(this.animationSlider.getValue())
            //this.currentAnimationGroup.play()
            //this.currentAnimationGroup.pause()
            document.getElementById('animationTitle').innerHTML = animationGroup.name
        }else{
            document.getElementById('bottomBar').classList.add("hide")
        }

    }
    updateAnimationToSlider(){
        this.pauseAnimation()
        //this.currentAnimationGroup.goToFrame(this.animationSlider.getValue())
        this.currentAnimationGroup.goToFrame((this.animEnd/100)*this.animationSlider.getValue())
        this.currentAnimationGroup.play()
        this.currentAnimationGroup.pause()
    }
    updateSliderToAnimation(){
        if (window.interval) {
            clearInterval(window.interval);
        }
        
        window.interval = setInterval(() => {
            if(this.currentAnimationGroup.animatables[0]/* .masterFrame */ == undefined || this.currentAnimationGroup.animatables[0].masterFrame >= (this.animEnd*0.97)){
                this.animationSlider.setValue(100)
                this.currentAnimationGroup.goToFrame((this.animationSlider.getValue()/100)*this.animEnd)
                this.pauseAnimation()
            }
            this.animationSlider.setValue((this.currentAnimationGroup.animatables[0].masterFrame/this.animEnd) *100)
        }, 100);
    }
}