export class XRManager{  
    constructor(appManager){
        this.appManager = appManager

        if(this.checkDeviceCompatibility()) this.initialise()
    }

    async checkDeviceCompatibility(){
        this.resetModelButton = document.getElementById('remove-anchor-btn')
        this.viewInARButton = document.getElementById("view-in-AR-btn")

        const supported = await BABYLON.WebXRSessionManager.IsSessionSupportedAsync("immersive-ar");
        if (supported) {
            this.viewInARButton.onclick = () => this.toggleSession()
            return true
        }
        else
            this.viewInARButton.classList.add("hide")
        
        return false
    }
          
    async initialise() {
        const uiElement = document.getElementById('body');
        this.xr = await this.appManager.scene.createDefaultXRExperienceAsync({
            uiOptions: {
              sessionMode: "immersive-ar",
              referenceSpaceType: "unbounded"
            },
            optionalFeatures: true
        });
        this.fm = this.xr.baseExperience.featuresManager;

        this.xrTest = this.fm.enableFeature(BABYLON.WebXRHitTest.Name, "latest", undefined,true,false);
        this.anchors = this.fm.enableFeature(BABYLON.WebXRAnchorSystem.Name, 'latest', undefined,true,false);
        this.xrBackgroundRemover = this.fm.enableFeature(BABYLON.WebXRBackgroundRemover.Name);
        this.domOverlay = this.fm.enableFeature(BABYLON.WebXRDomOverlay, "latest", { element: uiElement }, undefined, false)
        this.imageTracking = this.fm.enableFeature(BABYLON.WebXRFeatureName.IMAGE_TRACKING, "latest", {
            images: [
                {
                src: this.appManager.data.trackingMarker,
                    estimatedRealWorldWidth: 0.6
                },
            ]
        },true,false).isCompatible()
        console.log(BABYLON.WebXRFeaturesManager.GetAvailableFeatures())


        /* this.imageTrackingRoot = new BABYLON.TransformNode("imageTrackingRoot", this.appManager.scene);
        this.appManager.modelManager.root.parent = this.imageTrackingRoot;
        this.appManager.cameraManager.resetCameraTarget()

        this.hitMarkerFlat = this.setupHitMarker()
        this.hitMarkerImage = this.setupImageTrackingHelper()
        this.hitMarker = this.hitMarkerImage
        this.hitTest */;
        
        /* this.appManager.scene.onPointerDown = (evt, pickInfo) => {
            if(this.isUsingImageTracking)return;

            if (this.hitTest && this.anchors && this.xr.baseExperience.state === BABYLON.WebXRState.IN_XR && !this.modelPlaced) {
                this.anchors.addAnchorPointUsingHitTestResultAsync(this.hitTest);
                this.placeModel()
            }
        }  */
        //this.setupOnLeaveXR();
        //this.setupOnEnterXR();
    }

    async toggleSession(){
        if(this.xr.baseExperience.state != BABYLON.WebXRState.IN_XR){
            try {
                await this.xr.baseExperience.enterXRAsync("immersive-ar", "unbounded", this.xr.renderTarget);
            }
            catch(err) {
                console.log("error", err)
                this.supported = false;
                return;
            }
        }else{
            try {
                await this.xr.baseExperience.exitXRAsync();
            }
            catch(err) {
                this.supported = false;
                return;
            }
            //this.resetModel();
        } 
    }
}