export class TransparentController{

    constructor(appManager){
        this.appManager = appManager

    }

    highlightParts(parts){
        if(!this.appManager.modelManager.currentModel.models) return
        var product = this.appManager.modelManager.currentModel.models;
        if(parts && parts.length > 0){
            product.meshes.forEach(m => {
                if(m.material != null){
                    m.visibility = 0.2;
                }
            });
            product.meshes.forEach(m => {
                parts.forEach(h => {
                    if(m.id == h){
                        m.visibility = 1;
                    }   
                });
            });
        }else{
            product.meshes.forEach(m => {
                m.visibility = 1;
            });
        }
    }

    setAllTransparent(value){
        if(!this.appManager.modelManager.currentModel.models) return
        let transparency = 1
        if(value) transparency = 0.2

        var product = this.appManager.modelManager.currentModel.models;
        product.meshes.forEach(m => {
            m.visibility = transparency;
        });
    }
}