import { Hotspot } from "../Objects/hotspot";

export class Scene{

    constructor(appManager, sceneData){
        this.appManager = appManager
        this.id = sceneData.id
        this.model = sceneData.model || undefined
        this.title = sceneData.title || undefined
        this.prompt = sceneData.prompt || undefined
        this.animStart = sceneData.animStart || undefined
        this.animEnd = sceneData.animEnd || undefined
        this.zoomLevel = sceneData.zoomLevel || undefined
        this.transparent =  sceneData.transparent
        this.highlightedParts = sceneData.highlight || undefined
        this.hotspots = sceneData.hotspots || undefined
        this.hotspotsArray = []

        this.initialise()
    }

    initialise(){
        //this.loadModel()
        this.createHotspots()
    }

    displayScene(){
        this.setZoomLevel()
        this.setAnimation()
        this.setTransparency()
        this.setHighlightedParts()
        this.setHotspotVisibility(true)
        this.appManager.sceneHistory.push(this.id)
        this.updateUI()
    }

    loadModel(){
        if(this.model != undefined)
            this.appManager.loadModel(this.model)
    }
    createHotspots(){
        if(this.hotspots == undefined) return

        this.hotspots.forEach((hotspotData) => {
            this.hotspotsArray.push(new Hotspot(this.appManager, hotspotData))
        })
    }

    updateUI(){
        document.getElementById('experienceTitle').innerHTML = this.title
        if(this.prompt == undefined) this.prompt = " "
        document.getElementById('overlaidInstructionsText').innerHTML = this.prompt
        document.getElementById('overlaidInstructionsText').classList.remove("hide")
        document.getElementById('overlaidInstructions').classList.remove("hide")
    }
    setZoomLevel(){
        if(this.zoomLevel != undefined)
            this.appManager.cameraManager.zoomCameraTo(this.zoomLevel)
    }
    setAnimation(){
        if(this.animStart == undefined) return    
        if(this.animEnd == undefined) return

        this.appManager.modelManager.currentModel.animationController.animationGroups[0].stop()
        this.appManager.modelManager.currentModel.animationController.animationGroups[0]
            .start(false, 1, this.animStart, this.animEnd);
    }
    setTransparency(){
        this.appManager.transparentController.setAllTransparent(this.transparent)
    }
    setHighlightedParts(){
        if(this.highlightedParts == undefined) return
        this.appManager.transparentController.highlightParts(this.highlightedParts)
    }
    setHotspotVisibility(value){
        this.hotspotsArray.forEach((hotspot) => {
            hotspot.setVisibility(value)
        })
    }
}