import { Scene } from "../Objects/scene"

export class SceneManager{  

    constructor(appManager){
        this.appManager = appManager
        this.currentSceneId
        this.scenes = []

        this.initialise()
    }

    initialise(){
        this.appManager.data.scenes.forEach(sceneData => {
            this.scenes.push(new Scene(this.appManager, sceneData))
        })
    }
    
    loadScene(id){
        if(id == "h"){
            this.appManager.homeScreen.displayHomeScreen()
        }else{
            this.currentSceneId = id
            let scene = this.getSceneById(id)
            this.hideLastScene()
            scene.displayScene()
        }
    }

    hideLastScene(){
        if(this.appManager.sceneHistory.length < 1 ||this.appManager.sceneHistory[this.appManager.sceneHistory.length-1]=="h") return
        let lastScene = this.getSceneById(this.appManager.sceneHistory[this.appManager.sceneHistory.length-1])
        lastScene.setHotspotVisibility(false)
    }

    goBackScene(){
        this.hideLastScene()
        this.appManager.sceneHistory.pop()
        let id = this.appManager.sceneHistory.pop()
        this.currentSceneId = id
        if(id == "h"){
            this.appManager.homeScreen.displayHomeScreen()
        }else{
            let scene = this.getSceneById(id)
            scene.displayScene()
        }   
    }

    getSceneById(id){
        let result = null
        this.scenes.forEach(scene => {
            if(scene.id == id) result = scene
        })
        return result
    }
}