export class Light{  
    constructor(appManager){
        this.appManager = appManager
        this.hemiLight = this.createHemisphereLight()
    }

    createHemisphereLight(){
        const light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(1, 1, 0))
        return light
    }
}