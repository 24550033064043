const BABYLON = require('babylonjs');
const GUI = require('babylonjs-gui');

import './main.css'
import 'babylonjs-loaders'
import "babylonjs-gui"
import { AppManager } from "./Scripts/Managers/appManager"


if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js').then(registration => {
        console.log('SW registered: ', registration);
      }).catch(registrationError => {
        console.log('SW registration failed: ', registrationError);
      });
    });
  }

let engine, scene, appManager

function renderTour(){
    engine = new BABYLON.Engine(document.getElementById("renderCanvas"),true, null, true)
    
    fetch("/content/json/dataNew.json")
        .then(response => response.json())
        .then(data => startRendering(data)) 
}

async function startRendering(data){
    scene = await createScene(data)
    engine.runRenderLoop(function (){
        scene.render() 
    }) 
}

var createScene = async function (data) {
    var scene = new BABYLON.Scene(engine)
    
    scene.clearColor = new BABYLON.Color3(30/255,30/255,30/255)
    appManager = new AppManager(engine, scene, data)
    return scene
}

window.addEventListener("resize", function () {
    engine.resize()
  })
  
window.addEventListener('DOMContentLoaded', (event) => {
    renderTour()
})

/* if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then(() => { console.log('Service Worker Registered'); });
} */
