
export class Line{  

    constructor(hotspot, part){
        this.appManager = hotspot.appManager
        this.hotspot = hotspot
        this.part = part
        this.line 
        this.points 

        this.hotspot
        this.hotspotMesh

        this.createMaterial()
        this.createLine()
    }

    createMaterial(){
        this.myMaterial = new BABYLON.StandardMaterial("myMaterial", this.appManager.scene);
        this.myMaterial.diffuseColor = new BABYLON.Color3(1, 1, 1);
        this.myMaterial.emissiveColor = new BABYLON.Color3(0.35, 0.35, 0.35);
    }

    async createLine(){
        let pos = this.part.getBoundingInfo().boundingBox.centerWorld
        let hotspotPos = pos.add(new BABYLON.Vector3(this.hotspot.position.x,this.hotspot.position.y,this.hotspot.position.z))
 
        let myPoints = [
            hotspotPos,
            pos
        ]

        let tube = BABYLON.MeshBuilder.CreateTube("tube", {path: myPoints, radius: 0.001, cap: 3, updatable: true}, this.appManager.scene);
        tube.material = this.myMaterial;

        if(this.hotspot.part){
            this.hotspot.hotspotMesh.parent = this.hotspot.sphere
            this.hotspot.hotspotMesh.position =new BABYLON.Vector3(0,0,0)
            tube.setParent(this.hotspot.part); 
        }
        else
            tube.setParent(this.hotspot.root); 

        this.line = tube
        this.line.setEnabled(false)
        //this.line.scaling._x = 0.9
        //this.line.scaling._y = 0.9
        this.line.scaling._z = 0.9
    }

}