import { CameraManager } from "./cameraManager"
//import { Options } from "../htmlElements/options"
import { XRManager } from "./xrManager"
import { MediaPlayer } from "../Utilities/mediaPlayer"
import { HomeScreen } from "../htmlElements/homeScreen"
//import { NFCReader } from "../Utilities/nfcReader"
import { TransparentController } from "../Utilities/transparentController"
import { LoadingScreen } from "../htmlElements/loadingScreen"
import { HelpPopup } from "../htmlElements/helpPopup"
import { BackButton } from "../htmlElements/backButton"
import { ModelManager } from "./modelManager"
import { SceneManager } from "./sceneManager"


export class AppManager{  

    constructor(engine, scene, data){
        this.engine = engine
        this.data = data
        this.scene = scene
        this.scenesLoaded = false
        this.sceneHistory = ["h"]

        console.log("appManager: ",this.data)
        
        this.loadingScreen = new LoadingScreen(this)
        this.cameraManager = new CameraManager(this)
        this.modelManager = new ModelManager(this)
        this.homeScreen = new HomeScreen(this)
        this.transparentController = new TransparentController(this)
        this.helpPopup = new HelpPopup(this)
        this.backButton = new BackButton(this)
        this.xrManager = new XRManager(this)
        /* 
        this.options = new Options(this)
        this.nfcReader = new NFCReader(this) */
    }

    loadScenes(){
        if(this.scenesLoaded) return
        this.loadingScreen.disable()
        this.sceneManager = new SceneManager(this)
        this.mediaPlayer = new MediaPlayer(this)
        this.scenesLoaded = true
    }
}