//import { HotspotManager } from "./hotspotManager";
import { AnimationController } from "../Utilities/animationController"
import { RotatePrompt } from "../htmlElements/rotatePrompt"

export class Model{  

    constructor(modelManager, url){
        this.modelManager = modelManager
        this.animationGroups = []
        this.animationController = new AnimationController(this.modelManager.appManager)
        this.models = null

        this.loadProductModel(url)
    }

    async loadProductModel(modelPath){
        let models
        let self = this
        this.animationGroups = []
        models = await BABYLON.SceneLoader.ImportMeshAsync(
            "",modelPath,"",
            this.modelManager.appManager.scene
        )
        console.log("model: ", models)

        if(models.animationGroups.length > 0){
            models.animationGroups.forEach(function(g) {
                self.animationController.addAnimationGroup(g)
                self.animationController.currentAnimationGroup = self.animationController.animationGroups[0]
            })
        } 
        models.meshes[0].position = new BABYLON.Vector3(0,0,0)
        models.meshes[0].parent = this.modelManager.root

        this.modelManager.appManager.scene.stopAnimation(models.meshes[0])
        this.models = models 
        this.modelManager.appManager.loadScenes()
        //models.meshes[0].scaling = new BABYLON.Vector3(0.6,0.6,0.6)

        //this.hotspotManager = new HotspotManager(this.appManager, this.appManager.data.experiences)
        if(this.rotatePrompt == undefined)
            this.rotatePrompt = new RotatePrompt(this.modelManager.appManager)
    }

    disposeModel(){
        this.appManager.scene.meshes.forEach(function(m) {
            if(m.id != "hdrSkyBox")
                m.dispose();
        });
        this.models = null
        this.root = null
        this.animationGroup = null
        this.animationControls = null
        this.slider = null

        document.getElementById('animationControls').classList.add("hide")
    }


    async getPartRefByName(name){
        let part = null
        this.models.meshes.forEach(m => {
            if(m.id === name){
                part = m
            }   
        });
        //if(part == null)
            //console.log(name)
        return part
    }
}